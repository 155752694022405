@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"















































.home
  box-sizing: border-box
  color: $color-ink

.home-body
  .content-home
    padding: 32px 0
    display: flex
    flex-flow: column

    .title-page
      padding: 16px 0
      font-size: 22px
