@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
































































.content-cards-modules
  display: flex
  flex-wrap: wrap

  .sas-box
    min-width: 30%
    margin-right: 1%
    margin-bottom: 16px

    h2
      font-size: 18px
      margin-bottom: 16px

    ul
      li
        margin: 8px 0
        a
          padding: 8px 0
